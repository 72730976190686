<template>
  <div class="card largebox-Container border-0">
    <div class="card-body p-0 border-0">
      <div class="">
        <div class="d-flex flex-column text-left duplicatesFound-Row">
          <div class="duplicatesFound-titleHolder border-0">
            <span class="duplicatesFound-Title">Duplicates Found</span>
            <span> <v-icon small id="duplicatesFound-help-icon" color="#2C9A65" style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="duplicatesFound-help-icon" placement="bottom">
                The number of individual records which we have identified as duplicates. We use Email, Phone and Address to identify duplicates.
              </b-tooltip></span>
          </div>

          <!-- <animate-number  class="duplicatesFound-Count" from="0" duration="1000"  :to=duplicatesFound()>
          </animate-number> -->
          <div id="chart">
            <apexchart height="220px" width="100%" type="pie" :options="chartOptions" :series="series"></apexchart>
          </div>
          <!-- <span class="duplicatesFound-Count">{{duplicatesFound()}}</span> -->

          <!-- <b-row class="duplicatesFound-Row duplicatesFound-Row-underline">
              <b-col>
                <span class="duplicatesFound-Summary">Duplicates Found</span>
              </b-col>
              <b-col>
                <span class="duplicatesFound-Summary">{{duplicatesFound()}}</span>
              </b-col>
          </b-row>
          <b-row class="duplicatesFound-Row duplicatesFound-Row-underline">
              <b-col>
                <span class="duplicatesFound-Summary">Records Not Matched</span>
              </b-col>
              <b-col>
                <span class="duplicatesFound-Summary">{{duplicatesNotFound()}}</span>
              </b-col>
          </b-row> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "duplicatesFound",
  props: {
    currentTask: Object,
  },
  data() {
    return {
      series: [this.duplicatesNotFound(), this.duplicatesFound()],
      chartOptions: {
        legend: {
          fontFamily: 'Poppins',
          fontSize: '14px'
        },
        labels: ['Unique Records', 'Duplicates Records'],
        fill: {
          colors: ['#3498DB','#F1C40F','#8E44AD','#2980B9','#2C3E50','#27AE60','#D35400','#C0392B','#BDC3C7','#34495E','#E67E22','#1ABC9C','#F39C12','#7F8C8D','#E74C3C'],
        },
        // colors:['#F44336', '#E91E63', '#9C27B0'],
        colors: ['#3498DB','#F1C40F','#8E44AD','#2980B9','#2C3E50','#27AE60','#D35400','#C0392B','#BDC3C7','#34495E','#E67E22','#1ABC9C','#F39C12','#7F8C8D','#E74C3C'],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }

  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
  },
  methods: {
    duplicatesNotFound() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // retVal = parseInt(currentTaskResults.noOfRecords) - parseInt(currentTaskResults.noOfAddressDups);
      retVal = parseInt(currentTaskResults.noOfRecords) - parseInt(currentTaskResults.noOfContactFullDups);
      return retVal;
    },
    duplicatesFound() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // retVal = currentTaskResults.noOfAddressDups;
      retVal = currentTaskResults.noOfContactFullDups;
      return retVal;
    }
  }
};
</script>

<style>
.duplicatesFound-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.duplicatesFound-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.duplicatesFound-Count {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #2C9A65;
}

.duplicatesFound-Summary {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.8;
}

.duplicatesFound-Row {
  width: 100%;
  height: 39px;
  margin-top: 25px;
  margin-left: 10px;
}

.duplicatesFound-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.duplicatesFound-row {
  width: 100%;
}

.duplicatesFound-titleHolder {

  padding-bottom: 10px;
}
</style>