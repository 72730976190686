<template>
  <div class="card largebox-Container border-0">
    <div class="card-body p-0 border-0">
      <div class="">
        <div class="d-flex flex-column text-left locations-Row">
          <div class="locations-titleHolder border-0">
            <span class="locations-Title">Locations</span>
            <span> <v-icon small id="locations-help-icon" color="#2C9A65" style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="locations-help-icon" placement="bottom">
                A summary of the common locations within your data. If you have no addresses, this will remain blank
              </b-tooltip></span>
          </div>
          <div id="chart">
            <apexchart type="pie" height="220px" width="100%" :options="chartOptions" :series="series"></apexchart>
          </div>
          <!-- <div>
<CanvasJSChart></CanvasJSChart>
          </div> -->
          <!-- <b-row>
              <b-col v-if="showGraph"> -->
          <!-- <div v-if="showGraph">
                  <PackChart :graphData="showRecords" :showheight="100" :showWidth="getWidth"></PackChart>
                </div> -->

          <!-- </b-col> -->
          <!-- <b-col>
                <div v-for="(item, i) in showRecords" v-bind:key="i" style="padding-top:10px;">

                    <b-row class="locations-Row">
                        <b-col>
                            <span class="locations-Summary">{{item.town}}</span>
                        </b-col>
                        <b-col  style="text-align:right">
                            <span class="locations-Summary">{{item.townCount}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="locations-Row">
                        <b-col>
                            <b-progress :value="item.townCount" :max="maxRecords()" class="mb-3" style="background-color:rgba(115, 136, 169, 0.35);"></b-progress>
                        </b-col>
                    </b-row>

                </div>
              </b-col> -->
          <!-- </b-row> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { mapGetters } from "vuex";
// import CanvasJSChart from '../../../../assets/CanvasJSVueComponent.vue';
// import PackChart from "./PackChart.vue";

export default {
  name: "locations",
  props: {
    currentTask: Object,
  },
  components: {
    // PackChart
    // CanvasJSChart
  },
  data() {
    return {
      // showGraph:false,
      dataItems: [],
      series: [],
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              size: '100%'
            }
          }
        },
        dataLabels: {
          enabled: true,
          position: 'bottom',
          horizontalAlign: 'middle',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },
        legend: {
          fontFamily: 'Poppins',
          fontSize: '14px'
        },
        chart: {
          height: 350,
          type: 'donut',
        },
        fill: {
          colors: ['#8E44AD', '#2980B9', '#2C3E50', '#27AE60', '#D35400', '#C0392B', '#BDC3C7', '#34495E', '#E67E22', '#1ABC9C', '#F39C12', '#7F8C8D', '#3498DB', '#E74C3C', '#F1C40F'],
        },
        labels: [],
        // colors:['#F44336', '#E91E63', '#9C27B0'],
        colors: ['#8E44AD', '#2980B9', '#2C3E50', '#27AE60', '#D35400', '#C0392B', '#BDC3C7', '#34495E', '#E67E22', '#1ABC9C', '#F39C12', '#7F8C8D', '#3498DB', '#E74C3C', '#F1C40F'],
      },
      // 8E44AD
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    showRecords() {
      var list = this.dataItems;
      return list.sort((a, b) => (a.townCount < b.townCount) ? 1 : -1).slice(0, 6);

    },
    getWidth() {
      return 100;
    }
  },
  beforeMount() {
    var currentTaskResults = JSON.parse(this.currentTask.results);
    this.dataItems = currentTaskResults.sampleTowns;
    for (var i = 0; i < this.dataItems.length; i++) {
      this.series.push(this.dataItems[i] ? this.dataItems[i].townCount : '');
      this.chartOptions.labels.push(this.dataItems[i] ? this.dataItems[i].town : '');
    }
  },
  mounted() {
    var currentTaskResults = JSON.parse(this.currentTask.results);
    this.dataItems = currentTaskResults.sampleTowns;
    setTimeout(() => {
      this.showGraph = true;
    }, 1000);

  },
  methods: {
    maxRecords() {
      var count = 0;
      this.dataItems.forEach(dataItem => {
        count = count + parseInt(dataItem.townCount);
      });
      return count;
    },
    locations() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfRecords;
      return retVal;
    },
  }
};
</script>

<style>
.locations-Container {
  min-height: 400px;
}

.locations-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.locations-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.locations-Summary {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.8;
}

.locations-Row {
  width: 100%;
  height: 39px;
  margin-top: 25px;
  margin-left: 10px;
}

.locations-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.locations-chartHolder {
  width: 40%;
  margin-left: 30%;
}

.locations-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.progress-bar {
  background-color: #147ad6 !important;
}
</style>