<template>
  <div class="card smallbox-Container border-0">
    <div class="card-body">
      <div class="">
        <div class="d-flex flex-column text-left">
          <div class="addressesBase-titleHolder border-0">
            <span class="addressesBase-Title">Addresses</span>
            <span> <v-icon id="addressesBase-help-icon" color="#2C9A65" small style="  left: 6px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="addressesBase-help-icon" placement="bottom">
                A breakdown of the addresses we were able to improve within your data
              </b-tooltip></span>
          </div>

          <!-- <div><br/>
            <v-icon
              id="addressesBase-help-icon"
              small
            class="review-Helpbutton-Container">mdi-help-circle-outline</v-icon>
            <b-tooltip target="addressesBase-help-icon" placement="bottom">
              Broken down to show Valid, Invalid and Blank Email Addresses
            </b-tooltip>
          </div> -->
          <!-- <div class="row">
            <div class="col-sm-4 addressesBase-chartHolder">
            <donut-chart :chart-data="chartdata" :options="options"></donut-chart>
          </div>
         
           </div> -->

          <!-- <div class="progress blue">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">{{validPercentage()}}%</div>
            </div> -->
          <br>

          <div class="d-flex">
            <div class="col-xl-6">

              <div>
                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Imported: </span>
                      <span class="summarycount">{{totalRecords()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Improved: </span>
                      <span class="summarycount" style="color:#33b985;">{{valid()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <div class="row">
                    <b-col>
                      <span class="summarydata">Unchanged: </span>
                      <span class="summarycount">{{invalid()}}</span>
                    </b-col>
                  </div>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <div class="row">
                    <b-col>
                      <span class="summarydata">Blank: </span>
                      <span class="summarycount">{{blank()}}</span>
                    </b-col>
                  </div>
                </div>


              </div>
            </div>
            <div class="col-xl-6">
              <vue-ellipse-progress :progress=validPercentage() fontSize="25px" fontweight="500" :size="150"
                color="#2C9A65" :thickness="15" style="margin-top: -39px;">
                <span slot="legend-value">%</span>
                <p slot="legend-caption">Improved</p>
              </vue-ellipse-progress>
            </div>
          </div>




          <!-- <div style="margin-left: 26px; margin-top: -3px;">			
              <vue-ellipse-progress 
                  :progress=validPercentage()
                  fontSize="30px"
                  fontweight="500" 
                  :size="150"
              >
              <span slot="legend-value">%</span>
              <p slot="legend-caption">Improved</p>    
              </vue-ellipse-progress>
          </div> -->

          <!-- <div style=" margin-top: -10%;">
              <span class="percentage">{{validPercentage()}}% </span>
              <span style=" color: #3f6f86; margin-left: -12px; font-size: 14px; font-weight: 300;">Validated</span>
            </div> -->

          <!-- <br> -->
          <!-- <b-row class="addressesBase-Row">
            <b-col cols="4" style="margin-bottom: 10px;text-align: right;">
              <span class="addressesBase-Summary" >{{totalRecords()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                <span class="addressesBase-Summary" style="color:#3f6f86;">Imported</span>
                
              </b-col>
              <b-col cols="4" style="margin-bottom: 10px; text-align: right;">
                <span class="addressesBase-Summary" >{{valid()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
               
                <span class="addressesBase-Summary" style="color:#33b985;">Improved</span>
              </b-col>
              <b-col cols="4" style="margin-bottom: 10px;text-align: right;">
                <span class="addressesBase-Summary" style="text-align: right;">{{invalid()}}</span>
                
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                <span class="addressesBase-Summary" style="color:#black;">Unchanged/Blank</span>

              </b-col>
          </b-row> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import DonutChart from './DonutChart.js'

export default {
  name: "addresses",
  props: {
    currentTask: Object,
  },
  components: {
    // DonutChart
  },
  data() {
    return {
      // chartdata: {
      //     labels: ["Imported", "Valid", "Invalid"],
      //     datasets: [
      //         {
      //         label: "Data One",
      //         backgroundColor: ["#3f6f86",  "#33b985" ,"#ec6666"],
      //         data: [this.totalRecords(), this.valid(), this.invalid()]
      //         }
      //     ],
      // },
      options: {
        legend: {
          display: false
        },

      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.fillData()
  },
  methods: {
    addresses() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfAddressRecords;
      return retVal;
    },
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    invalid() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // retVal = parseInt(currentTaskResults.noInvalidEmails) + parseInt(currentTaskResults.noInvalidEmails);
      retVal = parseInt(currentTaskResults.noAddressUnChangedRecords);
      if(parseInt(currentTaskResults.noOfAddressRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    blank() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // retVal = parseInt(currentTaskResults.noInvalidEmails) + parseInt(currentTaskResults.noInvalidEmails);
      retVal = parseInt(currentTaskResults.noAddressBlankRecords);
      if(parseInt(currentTaskResults.noOfAddressRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    valid() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noAddressChangedRecords);
      if(parseInt(currentTaskResults.noOfAddressRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    totalRecords() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfAddressRecords;
      if(parseInt(currentTaskResults.noOfAddressRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    validPercentage() {
      var retVal = '';
      var validated = JSON.parse(this.currentTask.results);
      validated = parseInt(validated.noAddressChangedRecords);
      var totalRecords = JSON.parse(this.currentTask.results);
      totalRecords = totalRecords.noOfAddressRecords;
      //totalRecords  = parseInt(totalRecords.noOfRecords) - (parseInt(totalRecords.noAddressUnChangedRecords) + parseInt(totalRecords.noAddressBlankRecords) );
      retVal = (validated * 100 / totalRecords).toFixed(1);
      // return ((validated / 100)).toFixed(0)
      if(parseInt(validated.noOfAddressRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    // blank() {
    //     var retVal = '';
    //     var currentTaskResults = JSON.parse(this.currentTask.results);
    //     retVal = parseInt(currentTaskResults.noOfRecords) - (parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) );
    //     return retVal;
    // }          

  }
};
</script>

<style>
.addressesBase-Container {
  min-height: 400px;
}

.addressesBase-Title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.addressesBase-Count {
  font-family: 'Poppins';
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.addressesBase-Summary {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3D415B;
  opacity: 0.8;
}

.addressesBase-Row {
  width: 100%;

}

/* .addressesBase-Row-underline {
    border-bottom: solid 1px #e9eef3;
} */
.addressesBase-chartHolder {
  width: 35%;
  margin-left: 30%;
}

.addressesBase-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.percentage {
  font-family: 'Poppins';
  font-size: 120px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
  margin-left: 3px;
}
</style>