<template>
  <div class="card largebox-Container border-0">
    <div class="card-body p-0 border-0">
      <div class="">
        <div class="d-flex flex-column text-left emailAddresses-Row">
          <div class="emailAddresses-titleHolder border-0">
            <span class="emailAddresses-Title">Email Addresses</span>
            <span> <v-icon id="emailAddresses-help-icon" color="#2C9A65" small style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="emailAddresses-help-icon" placement="bottom">
                A breakdown of the email addresses we were able to validate for each record
              </b-tooltip></span>
          </div>
          <div id="chart">
            <apexchart height="220px" width="100%" type="pie" :options="chartOptions" :series="series"></apexchart>
          </div>
          <!-- <div v-for="(item, i) in showRecords" v-bind:key="i">
                <b-row class="emailAddresses-Row">
                    <b-col>
                      <span class="show-percentage" style="float: right;">{{ item.amount }}%</span>
                      <VueJsProgress  :title=item.name  :percentage=item.amount customBgColor="#2C9A65"   :delay="600" :striped="true" show-text :animation="true"></VueJsProgress >
                    </b-col>
                  </b-row>
                </div> -->

          <!-- <b-col>
                        <span class="emailAddresses-Summary">{{item.name}}</span>
                    </b-col>
                    <b-col  style="text-align:right">
                        <span class="emailAddresses-Summary">{{item.amount}}</span>
                    </b-col> -->

          <!-- <b-row class="emailAddresses-Row">
                    <b-col>
                        <b-progress :value="item.amount" :max="maxRecords()" class="mb-3" style="background-color:rgba(115, 136, 169, 0.35);"></b-progress>
                    </b-col>
                </b-row> -->



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import VueJsProgress from 'vue-js-progress'

export default {
  name: "emailAddresses",
  props: {
    currentTask: Object,
  },
  components: {
    // VueJsProgress,
  },
  data() {
    return {
      // dataItems: [
      //     {
      //         name:'Google',
      //         amount:this.google(),
      //     },
      //     {
      //         name:'Outlook',
      //         amount:this.outlook(),
      //     },
      //     {
      //         name:'Yahoo',
      //         amount:this.yahoo(),
      //     },
      //     {
      //         name:'AOL',
      //         amount:this.aol(),
      //     },
      //     {
      //         name:'Company',
      //         amount:this.company(),
      //     },
      //     {
      //         name:'Unknown',
      //         amount:this.unknown(),
      //     },


      // ]
      series: [this.google(), this.outlook(), this.yahoo(), this.aol(), this.company(), this.unknown()],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        legend: {
          fontFamily: 'Poppins',
          fontSize: '14px'
        },
        fill: {
          // colors: ['#F44336', '#E91E63', '#9C27B0', '#FFBF00', '#DFFF00', '#5D6D7E']
          colors: ['#8E44AD', '#2980B9', '#2C3E50', '#27AE60', '#D35400', '#C0392B', '#BDC3C7', '#34495E', '#E67E22', '#1ABC9C', '#F39C12', '#7F8C8D', '#3498DB', '#E74C3C', '#F1C40F'],
        },
        labels: ['Google', 'Outlook', 'Yahoo', 'AOL', 'Company', 'Other'],
        // colors: ['#F44336', '#E91E63', '#9C27B0', '#FFBF00', '#DFFF00', '#5D6D7E'],
        colors: ['#8E44AD', '#2980B9', '#2C3E50', '#27AE60', '#D35400', '#C0392B', '#BDC3C7', '#34495E', '#E67E22', '#1ABC9C', '#F39C12', '#7F8C8D', '#3498DB', '#E74C3C', '#F1C40F'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],

        dataLabels: {
          enabled: true,
          // formatter: function(val, opts) {
          //   const seriesIndex = opts.seriesIndex;
          //   const seriesPercent = opts.w.globals.seriesPercent[seriesIndex];
          //   return `${seriesPercent.toFixed(1)}%`;
          // },
          style: {
            fontSize: '14px',
            fontWeight: 600,
            colors: ['#ffffff']

          },
        }

      },
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    showRecords() {
      var list = this.dataItems;
      //return list.sort((a, b) => (a.amount < b.amount) ? 1 : -1).slice(0, 6);
      return list;
    }
  },
  mounted() {
    //this.fillData()      
  },
  methods: {
    getTotalValue() {
      // calculate the total value of the pie chart data
      let totalValue = 0
      this.series.forEach((item) => {
        totalValue += item
      })
      return totalValue
    },
    maxRecords() {
      var count = 0;
      this.dataItems.forEach(dataItem => {
        count = count + parseInt(dataItem.amount);
      });
      return count;
    },
    company() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // var totalcount = parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) ;
      retVal = parseInt(currentTaskResults.noOfEmailValid) - (parseInt(currentTaskResults.noGoogleEmails) + parseInt(currentTaskResults.noOutlookEmails) + parseInt(currentTaskResults.noYahooEmails) + parseInt(currentTaskResults.noAOLEmails) + parseInt(currentTaskResults.noInvalidEmails));
      // retVal= (retVal * 100 / totalcount).toFixed(0);
      return retVal;
    },
    google() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // var totalcount = parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) ;
      retVal = parseInt(currentTaskResults.noGoogleEmails);
      // retVal= (retVal * 100 / totalcount).toFixed(0);
      return retVal;
    },
    outlook() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // var totalcount = parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) ;
      retVal = parseInt(currentTaskResults.noOutlookEmails);
      // retVal= (retVal * 100 / totalcount).toFixed(0);
      return retVal;
    },
    yahoo() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // var totalcount = parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) ;
      retVal = parseInt(currentTaskResults.noYahooEmails);
      // retVal= (retVal * 100 / totalcount).toFixed(0);
      return retVal;
    },
    aol() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // var totalcount = parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) ;
      retVal = parseInt(currentTaskResults.noAOLEmails);
      // retVal= (retVal * 100 / totalcount).toFixed(0);
      return retVal;
    },
    unknown() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // var totalcount = parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) ;
      retVal = parseInt(currentTaskResults.noInvalidEmails);
      // retVal= (retVal * 100 / totalcount).toFixed(0);
      return retVal;
    },
  }
};
</script>

<style>
.emailAddresses-Container {
  min-height: 400px;
}

.emailAddresses-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.emailAddresses-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.emailAddresses-Summary {
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3D415B;
}

.emailAddresses-Row {
  width: 100%;
  height: 39px;
  margin-top: 25px;
  margin-left: 10px;
}

.emailAddresses-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.emailAddresses-chartHolder {
  width: 40%;
  margin-left: 30%;
}

.emailAddresses-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.progress-bar {
  background-color: #147ad6 !important;
}

/* .apexcharts-tooltip.apexcharts-theme-dark {
  display: none;
} */
</style>