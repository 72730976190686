<template>
  <div class="border-0">
    <div class="mt-7 pb-0">
      <div class="">
        <div class="d-flex flex-column text-center sourcedDetails-Row">
          <div class="sourcedDetails-titleHolder">
            <span class="sourcedDetails-Title">Improved Records</span>
            <span> <v-icon id="sourcedDetails-help-icon" color="#2C9A65" small style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="sourcedDetails-help-icon" placement="bottom">
                The number of individual records which have had one improvement or more
              </b-tooltip></span>
          </div>

          <animate-number style="font-size: 24px;
                  text-align: center;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  color: #2C9A65;" from="0" duration="1000" :to=improved()>
          </animate-number>
          <!-- <span class="sourcedDetails-Count">{{improved()}}</span> -->



          <!-- <b-row class="sourcedDetails-Row sourcedDetails-Row-underline">
              <b-col>
                <span class="sourcedDetails-Summary">Orignial</span>
              </b-col>
              <b-col>
                <span class="sourcedDetails-Summary">{{unchanged()}}</span>
              </b-col>
          </b-row>
          <b-row class="sourcedDetails-Row sourcedDetails-Row-underline">
              <b-col>
                <span class="sourcedDetails-Summary">Records Improved</span>
              </b-col>
              <b-col>
                <span class="sourcedDetails-Summary">{{improved()}}</span>
              </b-col>
          </b-row> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "sourcedDetails",
  props: {
    currentTask: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(date);
    },
    unchanged() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfRecords) - parseInt(currentTaskResults.noChangedRecords);
      return retVal;
    },
    improved() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noChangedRecords);
      return retVal;
    },
  }
};
</script>

<style>
.sourcedDetails-Title {
  font-family: 'Poppins';
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.sourcedDetails-Count {
  font-family: 'Poppins';
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #2C9A65;
}

.sourcedDetails-Summary {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.8;
}

.sourcedDetails-Row {
  width: 100%
}

.sourcedDetails-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.sourcedDetails-row {
  width: 100%;
}

.sourcedDetails-titleHolder {

  padding-bottom: 10px;
}
</style>