import { render, staticRenderFns } from "./PhoneNumbersBase.vue?vue&type=template&id=65f1524e&"
import script from "./PhoneNumbersBase.vue?vue&type=script&lang=js&"
export * from "./PhoneNumbersBase.vue?vue&type=script&lang=js&"
import style0 from "./PhoneNumbersBase.vue?vue&type=style&index=0&id=65f1524e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports