<template>
    <div class="tagdiv" :class="{ 'tag': true, 'selected': isSelected }" @click="toggleSelection">
        <span class="tag-text">{{ tag }}</span>
        <span class="delete-icon" @click.stop="removeTag">&#10005;</span>
        <!-- <span class="">&nbsp;</span> -->
    </div>    
</template>

<script lang="ts">
export default {
    props: ['tag', 'isSelected'],
    methods: {
        toggleSelection() {
            this.$emit('toggle', this.tag);
        },
        removeTag() {
            console.warn('removetag clicked');
            this.$emit('remove', this.tag);
        }
    }
};
</script>

<style scoped>
.tagdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
    margin-right: 10px;
    /* width: 269px; */
   
}

.tag-text {
    margin-right: 15px;
    overflow: auto;
    white-space: nowrap;
    /* Adjust the spacing between the tag text and delete icon */
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    margin-right: 5px;
    /* background-color: #eee; */
    background-color: #33b985;
    color:#ffffff;
    cursor: pointer;
    position: relative;
    font-size: 16.5px;
    overflow-x: auto;
    min-width: 310px;
    /* white-space: nowrap; */
}

.selected {
    background-color: #33b985;
    color: #ffffff;
}

.delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    margin-left: 10px;
    cursor: pointer;
}
</style>
