<template>
  <div class="greybackgroundmatchscreen">
    <div v-if="showCurrentTask">
      <div class="mb-6 mt-4 d-flex justify-content-between">
        <div class="col-xl-8">
          <div class="d-flex">
            <div style="display: flex">
              <button class="backbutton" @click="importGoback">
                <i class="fas fa-arrow-left" style="color: #000000"></i>
              </button>
            </div>
            <div class="d-flex">
              <div class="matchbox">
                <inline-svg
                  class="matchicon"
                  width="30"
                  height="30"
                  src="media/svg/icons/General/matched_fileicon.svg"
                />
              </div>
            </div>
            &nbsp;&nbsp;
            <div style="display: flex; align-items: center; margin-top: 12px">
              <p class="filename">{{ currentTask.title }}</p>
            </div>
          </div>
        </div>

        <div
          class="col-xl-4"
          style="display: flex; align-items: center; justify-content: end"
        >
          <!-- <b-row> -->
          <!-- <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col> -->
          <!-- <b-col cols="4"> -->
          <div v-if="currentTask.stage == 'Finished'" class="">
            <button
              data-v-step="purchase"
              @click="purchaseModalShow = !purchaseModalShow"
              class="btn btn-primary purchasebutton"
            >
              Purchase Cleaned Data
            </button>
          </div>
          <div v-else-if="currentTask.stage == 'Paid'">
            <div class="d-flex">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <button
                  data-v-step="paid"
                  @click="downloadFile()"
                  type="reset"
                  class="btn btn-primary purchasebutton mb-5"
                  ref="kt_save_changes"
                >
                  Download Your Data
                </button>
              </div>
            </div>

            <div class="d-flex">
              <div class="mr-5">
                <button
                  data-v-step="paid"
                  @click="downloadModalShow = !downloadModalShow"
                  type="reset"
                  class="btn btn-success purchasebutton"
                  ref="kt_save_changes"
                >
                  Customise Export Format-1
                </button>
              </div>
              <div class="">
                <button
                  data-v-step="paid"
                  @click="DownloadModalShowStyle2 = !DownloadModalShowStyle2"
                  type="reset"
                  class="btn btn-success purchasebutton"
                  ref="kt_save_changes"
                >
                  Customise Export Format-2
                </button>
              </div>
            </div>
          </div>
          <!-- </b-col>
          </b-row> -->
        </div>
      </div>
      <!-- <b-row class="DashboardReview-HeaderRow">
        <b-col class="Dashboard-Go-Back" cols="1" @click="importGoback"
          >Go Back</b-col
        >
        <b-col class="Dashboard-Readr-Campaign-Dataxls" cols="4"> -->
      <!-- <v-icon large style="font-size:38px;">mdi-file-excel</v-icon> -->
      <!-- <p class="DashboardReviewMatch-Map-your-Data">
             File Name: {{ currentTask.title }}
          </p> -->
      <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <button id="help"  
                  @click="startTour(true)">
                  <inline-svg  width="30" height="30" src="media/svg/icons/General/Help-Icon.svg"  />
            </button>            -->

      <!-- <a href="/media/csv/Sample_RMD_Export_Layout.csv"
              v-on:click="setActiveTab"
              class="sampledata"
              data-toggle="tab"
              data-tab="0"
              download="Sample_RMD_Export_Layout.csv">
            <button type="" class="btn btn-success mr-2 dashboard-action-button" ref="kt_save_changes"> Sample RMD Export </button>
          </a>			 
       </b-col>-->

      <div class="row">
        <b-row class="DashboardReview-HeaderRow">
          <!-- <a href="/media/csv/Sample_RMD_Export_Layout.csv"
          v-on:click="setActiveTab"
          class="sampledata"
          data-toggle="tab"
          data-tab="1"
          download="Sample_RMD_Export_Layout.csv">
        <button type="" class="btn btn-success mr-2 dashboard-action-button" ref="kt_save_changes"> Sample RMD Export </button>
      </a>			 -->

          <b-col>
            <button
              data-v-step="stats"
              type="reset"
              class="btn btn-success mr-2 Review-Action-Data-Button-selected"
              ref="kt_view_stats"
              @click="doStats()"
              style="height: 50px; width: 142px"
            >
              View Stats
            </button>
          </b-col>
          <b-col>
            <button
              data-v-step="sample"
              type="reset"
              class="btn btn-success mr-2 Review-Action-Data-Button Review-Action-Data-Button"
              ref="kt_view_stats_data"
              @click="doData()"
              style="height: 50px; width: 206px"
            >
              View Sample Data
            </button>
          </b-col>
          <b-col>
            <button
              data-v-step="sampledownload"
              type="reset"
              class="btn btn-success mr-2 Review-Action-Data-Button Review-Action-Data-Button w-10"
              ref="kt_view_stats"
              style="height: 50px; width: 210px"
              @click="downloadSampleFile"
            >
              Download Sample
            </button>
          </b-col>
        </b-row>
      </div>

      <div class="row mb-4">
        <div class="col-xl-8">
          <div
            class="row bg-white"
            style="
              border-radius: 30px;
              height: 112px;
              margin-left: 0px;
              margin-right: 0px;
            "
          >
            <div class="col-sm-4">
              <TotalRecords :currentTask="currentTask"></TotalRecords>
            </div>
            <div class="col-sm-4">
              <SourcedDetails :currentTask="currentTask"></SourcedDetails>
            </div>
            <div class="col-sm-4">
              <EstimateFields :currentTask="currentTask"></EstimateFields>
            </div>
          </div>
          <div style="margin-top: 20px">
            <div class="row mb-2" style="height: 249px">
              <div
                class="col-sm-6"
                style="cursor: pointer"
                @click="goData('contact')"
              >
                <ContactNames :currentTask="currentTask"></ContactNames>
              </div>
              <div
                class="col-sm-6"
                style="cursor: pointer; margin-left: -9px"
                @click="goData('address')"
              >
                <AddressesBase :currentTask="currentTask"></AddressesBase>
              </div>
            </div>
            <div class="row mb-2" style="height: 249px">
              <div
                class="col-sm-6"
                style="cursor: pointer"
                @click="goData('contact')"
              >
                <PhoneNumbers :currentTask="currentTask"></PhoneNumbers>
              </div>
              <div
                class="col-sm-6"
                style="cursor: pointer; margin-left: -9px"
                @click="goData('email')"
              >
                <EmailAddressesBase
                  :currentTask="currentTask"
                ></EmailAddressesBase>
              </div>
            </div>
            <div class="row" style="height: 249px">
              <div
                class="col-sm-6"
                style="cursor: pointer"
                @click="goData('contact')"
              >
                <Gender :currentTask="currentTask"></Gender>
              </div>
              <div
                class="col-sm-6"
                style="cursor: pointer; margin-left: -9px"
                @click="goData('phone')"
              >
                <PhoneTypes :currentTask="currentTask"></PhoneTypes>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="row" style="height: 295px">
            <div class="col-xl-12">
              <DuplicateFound :currentTask="currentTask"></DuplicateFound>
            </div>
          </div>

          <div class="row" style="height: 295px">
            <div
              class="col-xl-12"
              style="cursor: pointer"
              @click="goData('address')"
            >
              <Locations :currentTask="currentTask"></Locations>
            </div>
          </div>

          <div class="row bg-white ml-0" style="height: 295px">
            <div
              class="col-xl-12"
              style="cursor: pointer"
              @click="goData('email')"
            >
              <EmailAddresses :currentTask="currentTask"></EmailAddresses>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-xxl-3" style="cursor:pointer" @click="goData('contact')">
          <ContactNames :currentTask="currentTask"></ContactNames>
        </div>
        <div class="col-xxl-3" style="cursor:pointer" @click="goData('address')">
          <AddressesBase :currentTask="currentTask"></AddressesBase>
        </div> -->
      <div></div>
    </div>

    <div class="purchase_modal_section">
      <!-- hide-backdrop content-class="shadow" hide-footer/ -->
      <b-modal
        id="modal1"
        v-model="purchaseModalShow"
        title="Checkout"
        hide-footer
      >
        <PayModal :currentTask_id="this.$route.params.id"> </PayModal>
      </b-modal>
    </div>

    <div class="download_modal_section">
      <b-modal
        id="modal2"
        size="xl"
        style="width: 607px"
        v-model="downloadModalShow"
        title="Customise your Export"
        hide-footer
      >
        <DownloadModal
          :currentTask_id="this.currentTask.id"
          :currentTask_title="this.currentTask.title"
        ></DownloadModal>
      </b-modal>
    </div>
    <div class="download_modal_section">
      <b-modal
        id="modal2"
        size="xl"
        style="width: 607px"
        v-model="DownloadModalShowStyle2"
        title="Customise your Export"
        hide-footer
      >
        <DownloadModalStyle2
          :currentTask_id="this.currentTask.id"
          :currentTask_title="this.currentTask.title"
        ></DownloadModalStyle2>
      </b-modal>
    </div>
    <template>
      <v-tour name="onboarding-tour" :steps="steps" :options="myOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              :highlight="tour.highlight"
            >
              <template>
                <!-- v-if="tour.currentStep === 0" -->
                <div slot="actions" class="flex items-center justify-center">
                  <button @click="tour.skip" class="v-step__button-skip">
                    Skip
                  </button>
                  <button
                    v-if="tour.currentStep > 0"
                    @click="tour.previousStep"
                    class="v-step__button-previous"
                  >
                    Previous
                  </button>
                  <button
                    v-if="tour.currentStep !== stepsCount - 1"
                    @click="tour.nextStep"
                    class="v-step__button-next"
                  >
                    Next
                  </button>
                  <button
                    @click="tour.finish"
                    v-else
                    class="v-step__button-stop"
                  >
                    OK
                  </button>
                </div>
              </template>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

import { UPDATE_TASKS } from "@/core/services/store/tasks.module";
import { UPDATE_USER_SETTING } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";

import TotalRecords from "@/view/content/widgets/Review/TotalRecords";
import SourcedDetails from "@/view/content/widgets/Review/SourcedDetails";
import DuplicateFound from "@/view/content/widgets/Review/DuplicateFound";
import EstimateFields from "@/view/content/widgets/Review/EstimatePrice";
import ContactNames from "@/view/content/widgets/Review/ContactNames";
import EmailAddressesBase from "@/view/content/widgets/Review/EmailAddressesBase";
import EmailAddresses from "@/view/content/widgets/Review/EmailAddresses";
import Locations from "@/view/content/widgets/Review/Locations";
import PhoneNumbers from "@/view/content/widgets/Review/PhoneNumbersBase";
import PhoneTypes from "@/view/content/widgets/Review/PhoneTypes";
import Gender from "@/view/content/widgets/Review/Gender";
import AddressesBase from "@/view/content/widgets/Review/AddressesBase";
import PayModal from "@/view/pages/PurchaseModal.vue";
import DownloadModal from "@/view/pages/downloadModal.vue";
import DownloadModalStyle2 from "@/view/pages/downloadModalStyle2.vue";
import Swal from "sweetalert2";

import { useShepherd } from "vue-shepherd";
require("shepherd.js/dist/css/shepherd.css");

export default {
  name: "dashboard",
  props: ["helpValue", "refreshValue"],
  watch: {
    helpValue: function () {
      // watch it
      this.startTour(true);
    },
    refreshValue: function () {
      // this.tagclick("");
    },
    currentTasks() {
      this.showData();
    },
  },
  components: {
    PayModal,
    DownloadModal,
    DownloadModalStyle2,
    TotalRecords,
    SourcedDetails,
    DuplicateFound,
    EstimateFields,
    ContactNames,
    EmailAddresses,
    EmailAddressesBase,
    Locations,
    PhoneNumbers,
    PhoneTypes,
    Gender,
    AddressesBase,
  },
  data() {
    return {
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      purchaseModalShow: false,
      downloadModalShow: false,
      DownloadModalShowStyle2: false,
      flag: false,
      jsonData: {},
      tourSteps1: [],
      stepsCount: 100,
      steps: Array(100).fill({}),
      //vue-shephered variabale
      tour: null,
      filter: "",
      currentTask: null,
      showCurrentTask: false,
    };
  },
  mounted() {
    // var id =this.$route.params.id;
    var downloadflag = this.$route.query.downloadflag;

    if (this.tour != null) {
      if (this.tour.steps.length >= 0) {
        this.tour.steps.forEach((step) => {
          this.tour.removeStep(step.id);
          // step.destroy();
        });
      }
    }

    (this.tour = useShepherd({ useModalOverlay: true })), this.getData("", "");

    if (downloadflag == "Yes") {
      this.showData();
      // sleep(10);
      this.downloadFile();
    }
  },
  updated() {
    if (this.tour.steps.length == 0) {
      setTimeout(() => {
        this.startTour();
      }, 2000);
    }
    //this.helpValue=new Date();
  },
  beforeDestroy() {
    clearInterval(this.polling);

    if (this.tour.steps.length >= 0) {
      this.tour.complete();
    }

    // if (tour.steps.length > 0) {
    //   if (typeof tour.stop === "function") {
    //     tour.stop();
    //   }
    // }
  },
  destroy() {
    // remove all steps
    for (var i = 0; i < 5; i++) {
      if (this.tour.steps.length >= 0) {
        this.tour.steps.forEach((step) => {
          this.tour.removeStep(step.id);
          // step.destroy();
        });
      }
    }
  },
  methods: {
    turnOffTour() {
      if (
        this.currentUser.userData.onboarding_flag == "1" ||
        this.currentUser.userData.onboarding_flag == true
      ) {
        var startHelp = document.getElementById("startHelpRef");
        startHelp.click();
      }
    },
    tourComplete() {
      //nothing todo here, just hide the current onboarding when OK button clicked

      var onboardingFlag =
        this.$store.getters.currentUser.userData.onboarding.substr(0, 1);
      var onboardingStep =
        this.$store.getters.currentUser.userData.onboarding.substr(2, 5);
      var heatmap = this.$store.getters.currentUser.userData.heatmap;
      var onboarding_flag =
        this.$store.getters.currentUser.userData.onboarding_flag;
      if (onboarding_flag == 1 && onboardingFlag == "N") {
        onboardingStep = parseInt(onboardingStep) + 1;

        var onboarding = onboardingFlag + "-" + onboardingStep;
        onboarding_flag = 0;

        this.$store.dispatch(
          UPDATE_USER_SETTING,
          { onboarding, heatmap, onboarding_flag },
          this.$store.getters.currentUser.userData.id
        );

        this.$store.commit("setUserSetting", {
          onboarding: onboarding,
          heatmap: heatmap,
          onboarding_flag: onboarding_flag,
        });
      }
    },
    startTour() {
      var onboarding_flag = false;
      if (
        this.currentUser.userData.onboarding_flag == "1" ||
        this.currentUser.userData.onboarding_flag == true
      ) {
        onboarding_flag = true;
      }

      var onboardingStep = this.currentUser.userData.onboarding.substr(2, 5);

      //event fire off
      this.tour.off("cancel");
      this.tour.off("complete");

      // remove all steps
      for (var i = 0; i < 5; i++) {
        if (this.tour.steps.length >= 0) {
          this.tour.steps.forEach((step) => {
            this.tour.removeStep(step.id);
            // step.destroy();
          });
        }
      }

      // Delete any steps in the list from the reload
      this.tour.steps.forEach((step) => {
        step.destroy();
      });

      //step 1 = welcome
      if (onboardingStep >= 7 && onboardingStep <= 9) {
        // status step
        this.tour.addStep({
          title: "Data Report",
          text: `Your free audit report is below. We have provided you with statistics on your file and where we have managed to improve the records and more.`,
          attachTo: {
            element: "[data-v-step='stats']",
            on: "bottom",
          },
          buttons: [
            {
              action() {
                return this.cancel();
              },
              classes: "shepherd-button-secondary",
              text: "Skip",
            },
            {
              action: this.tour.next,
              text: "Next",
            },
          ],
          id: "step-5-1",
        });

        // sample data step
        this.tour.addStep({
          title: "View Sample Data",
          text: `You can also see an example of some sample records that have been improved by clicking here.`,
          attachTo: {
            element: "[data-v-step='sample']",
            on: "bottom",
          },
          buttons: [
            {
              action() {
                return this.cancel();
              },
              classes: "shepherd-button-secondary",
              text: "Skip",
            },
            {
              action: this.tour.next,
              text: "Next",
            },
          ],
          id: "step-5-2",
        });

        // sample data download
        this.tour.addStep({
          title: "Download Sample Data",
          text: `You can download cleaned sample records`,
          attachTo: {
            element: "[data-v-step='sampledownload']",
            on: "bottom",
          },
          buttons: [
            {
              action() {
                return this.cancel();
              },
              classes: "shepherd-button-secondary",
              text: "Skip",
            },
            {
              action: this.tour.next,
              text: "Next",
            },
          ],
          id: "step-5-3",
        });

        // purchase
        if (this.currentTask.stage == "Finished") {
          // purhcase button step
          this.tour.addStep({
            title: "Purchase your data",
            text: `Once you are ready to export your cleansed data file you can purchase this here. Once purchased files will remain downloadable for two weeks. If you choose to not purchase, your file will be automatically deleted after 7 days.`,
            attachTo: {
              element: "[data-v-step='purchase']",
              on: "bottom",
            },
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                classes: "shepherd-button-secondary",
                text: "Skip",
              },
              {
                action() {
                  return this.complete();
                  // return this.next();
                },
                text: "OK",
              },
            ],
            id: "step-5-4",
          });
        }

        if (this.currentTask.stage == "Paid") {
          // purhcase button step
          this.tour.addStep({
            title: "Download your data",
            text: `Your data is ready to download, click this button to get cleaned data.`,
            attachTo: {
              element: "[data-v-step='paid']",
              on: "bottom",
            },
            buttons: [
              {
                action() {
                  return this.cancel();
                },
                classes: "shepherd-button-secondary",
                text: "Skip",
              },
              {
                action() {
                  return this.complete();
                  // return this.next();
                },
                text: "OK",
              },
            ],
            id: "step-5-5",
          });
        }
      }

      //event fire on
      this.tour.on("cancel", this.turnOffTour);
      this.tour.on("complete", this.tourComplete);

      if (onboarding_flag === true && this.tour.steps.length > 0) {
        this.tour.start();
      }
    },
    getData(filter) {
      this.$store
        .dispatch(UPDATE_TASKS, {
          filter,
        })
        .then(() => {
          this.$nextTick(() => {
            this.showData();
          });
        });
    },
    downloadSampleFile() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      ApiService.setHeader();
      ApiService.getblob("apps/todo/task/downloadsample/" + this.currentTask.id)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          var date1 = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
          var outputFile = this.currentTask.title;
          outputFile = outputFile + "_RMDCLEANSE_" + date1 + "_SAMPLE.xlsx";
          outputFile = outputFile.replace(".", "_").replace("/", "_");

          fileLink.href = fileURL;
          // fileLink.setAttribute('download', this.currentTask.id + '.xlsx');
          fileLink.setAttribute("download", outputFile);
          document.body.appendChild(fileLink);

          Swal.close();
          fileLink.click();
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry Download Error, Try Later",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    showData() {
      if (this.currentTasks.length) {
        this.currentTasks.forEach((record) => {
          if (record.id == this.$route.params.id) {
            this.currentTask = record;
            this.showCurrentTask = true;
          }
        });
      }
    },
    goData(target) {
      this.$router.push({
        name: "dashboardreviewdata",
        params: { id: this.currentTask.id, section: target },
      });
    },
    downloadFile() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      ApiService.setHeader();
      ApiService.getblob("apps/todo/task/downloadall/" + this.currentTask.id)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          var date1 = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
          var outputFile = this.currentTask.title;
          outputFile = outputFile + "_RMDCLEANSE_" + date1 + ".xlsx";
          outputFile = outputFile.replace(".", "_").replace("/", "_");

          fileLink.href = fileURL;
          // fileLink.setAttribute('download', this.currentTask.id + '.xlsx');
          fileLink.setAttribute("download", outputFile);
          document.body.appendChild(fileLink);

          Swal.close();

          fileLink.click();
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title: "Sorry Download Error, Try Later",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    importGoback() {
      this.$router.push({ name: "dashboard" });
    },
    doStats() {
      this.$router.push({
        name: "dashboardreview",
        params: { id: this.currentTask.id },
      });
    },
    doData() {
      this.$router.push({
        name: "dashboardreviewdata",
        params: { id: this.currentTask.id },
      });
    },
    doPay() {
      Swal.fire({
        title: "Please Wait",
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        },
      });

      ApiService.setHeader();
      ApiService.get("apps/todo/task/stripecheckout/" + this.currentTask.id)
        .then(({ data }) => {
          Swal.close();
          window.location = data;
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            title:
              "Sorry we can not take a payment at the moment please try later",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentTasks", "currentUser", "isAuthenticated"]),
    ...mapMutations(["setUserSetting"]),
    getMappings() {
      return this.mappings;
    },
  },
};
</script>

<style>
.dashboardreview {
  background-color: #f5f9fd;
}

.dashboardreview .DashboardReview-HeaderRow {
  /* background-color: white; */
  margin-left: 14px;
  margin-top: -25px;
  padding-top: 25px;
}

.smallbox-Container {
  min-height: 250px;
  max-height: 250px;
  width: 102%;
}

/* .smallbox-Container2 {
  min-height: 220px;
  max-height: 220px;
  border: 0;
} */

.largebox-Container {
  min-height: 305px;
  max-height: 305px;
  border: 0;
}

.review-Helpbutton-Container {
  font-size: 16px;
  color: #f4bd07;
  float: center;
}

.Purchase-Data-Button {
  width: 237px;
  height: 51px;
  margin: 0 120px 21px 180px;
  background-color: rgb(51, 185, 133, 0.15) !important;
  border-color: rgb(51, 185, 133, 0.15) !important;
  color: #33b985 !important;
  font-size: 15px;
  float: right;
}

.Dashboard-Readr-Campaign-Dataxls {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #3f6f86;
  line-height: 70px;
}

.matchbox {
  width: 64px;
  border: 2px dashed rgb(149, 186, 242);
  margin-left: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.matchicon {
  margin-left: 18px;
}

.filename {
  /* width: 600px; */
  /* height: 30px; */
  /* flex-wrap: wrap; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* margin-top: 19px;
  margin-left: 15px; */
  /* identical to box height */

  color: #3d415b;
}

.purchasebutton {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  width: 238px;
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* margin-left: -62px; */
}

.summarydata {
  font-family: "Poppins";
  text-align: left;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.summarycount {
  font-family: "Poppins";
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #000000;
}

.legent-value {
  font-family: "Poppins";
  text-align: left;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.legent-caption {
  font-family: "Poppins";
  text-align: left;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.backbutton {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* modal-1 */
#modal1 .modal-header {
  border-bottom: 0 none;
}

#modal1 .modal-header .close {
  float: right;
  font-size: 31px;
  font-weight: 700;
  color: #000;
}

#modal1 .modal-dialog {
  position: absolute;
  top: 80px;
  right: 29px;
  width: 500px;
}

/* modal-2 */
#modal2 .modal-header {
  border-bottom: 0 none;
}

#modal2 .modal-header .close {
  float: right;
  font-size: 31px;
  font-weight: 700;
  color: #000;
}

#modal2 .modal-dialog {
  position: absolute;
  top: 10px;
  right: 29px;
  min-width: 500px;
  max-width: 1350px;
  /* height: 800px; */
}

.modal .modal-header .modal-title {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}
</style>
