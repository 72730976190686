<template>
  <div class="card smallbox-Container border-0">
    <div class="card-body">
      <div class="">
        <div class="d-flex flex-column text-left phoneNumbers-Row">
          <div class="phoneNumbers-titleHolder border-0">
            <span class="phoneNumbers-Title">Phone Numbers</span>
            <span> <v-icon small id="phoneNumbers-help-icon" color="#2C9A65" style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="phoneNumbers-help-icon" placement="bottom">
                A breakdown of the Phone Numbers we were able to validate for each record
              </b-tooltip></span>
          </div>
          <!-- <div><br/>
            <v-icon
              id="phoneNumbers-help-icon"
              small
            class="review-Helpbutton-Container">mdi-help-circle-outline</v-icon>
            <b-tooltip target="phoneNumbers-help-icon" placement="bottom">
              Broken down to show Valid, Improved and Missing Phone Numbers
            </b-tooltip>
          </div> -->
          <!-- <div class="row">
            <div class="phoneNumbers-chartHolder">
            <donut-chart :chart-data="chartdata" :options="options"></donut-chart>
          </div><br/>        
          </div> -->

          <br>
          <div class="d-flex">
            <div class="col-xl-6">

              <div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Imported: </span>
                      <span class="summarycount">{{totalRecords()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Valid Format: </span>
                      <span class="summarycount" style="color:#33b985;">{{valid()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Unchanged: </span>
                      <span class="summarycount">{{unchanged()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Blank: </span>
                      <span class="summarycount">{{blank()}}</span>
                    </b-col>
                  </b-row>
                </div>


              </div>
            </div>
            <div class="col-xl-6">
              <vue-ellipse-progress :progress=validPercentage() fontSize="25px" fontweight="500" :size="150"
                :thickness="15" color="#2C9A65" style="margin-top: -39px;">
                <span slot="legend-value">%</span>
                <p slot="legend-caption">Valid Format</p>
              </vue-ellipse-progress>
            </div>
          </div>
          <!-- <div style="margin-left: 26px; margin-top: -3px;">			
              <vue-ellipse-progress 
                  :progress=validPercentage()
                  fontSize="30px"
                  fontweight="500" 
                  :size="150"
              >
              <span slot="legend-value">%</span>
              <p slot="legend-caption">Valid Format</p>    
              </vue-ellipse-progress>
          </div> -->
          <!-- <div style=" margin-top: -10%;">
              <span class="percentage">{{validPercentage()}}% </span>
              <span style=" color: #3f6f86; margin-left: -12px; font-size: 14px; font-weight: 300;">Validated</span>
          </div> -->


          <!-- <br>
          
          <b-row class="phoneNumbers-Row"> -->
          <!--
              <b-col cols="6">
                <span class="phoneNumbers-Summary" style="color:#ec6666" >&bull;&nbsp;Unchanged</span>
              </b-col>
              <b-col cols="6">
                <span class="phoneNumbers-Summary">{{unchanged()}}</span>
              </b-col>
              -->
          <!-- <b-col cols="4"  style="margin-bottom: 10px;text-align: right;">
                <span class="phoneNumbers-Summary">{{totalRecords()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
               
                <span class="phoneNumbers-Summary" style="color:#3f6f86">Imported</span>
              </b-col>
              <b-col cols="4"  style="margin-bottom: 10px; text-align: right;">
                <span class="phoneNumbers-Summary">{{valid()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                
                <span class="phoneNumbers-Summary" style="color:#33b985">Valid Format</span>
              </b-col>
             
              <b-col cols="4"  style="margin-bottom: 10px;text-align: right;">
                <span class="phoneNumbers-Summary">{{unchanged()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">                
                <span class="phoneNumbers-Summary" style="color:#black">Unchanged/Blank</span>
              </b-col>
            
          </b-row> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import DonutChart from './DonutChart.js'

export default {
  name: "phoneNumbers",
  props: {
    currentTask: Object,
  },
  components: {
    // DonutChart
  },
  data() {
    return {
      // chartdata: {
      //     labels: [ "Imported", "Valid", "Unchanged"],
      //     datasets: [
      //         {
      //         label: "Data One",
      //         backgroundColor: ["#3f6f86",  "#33b985" ,"#ec6666"],
      //         data: [ this.totalRecords(), this.valid(), this.unchanged()]
      //         }
      //     ],
      // },
      options: {
        legend: {
          display: false
        },

      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.fillData()
  },
  methods: {
    totalRecords() {
      var retVal = 0;
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfPhoneRecords;
      return retVal;
    },
    phoneNumbers() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfPhoneRecords;
      return retVal;
    },

    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    unchanged() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt( currentTaskResults.noOfPhoneRecords) -
                parseInt(currentTaskResults.noOfPhoneBlank) -
                parseInt(currentTaskResults.noOfPhoneValidChanged)
              //  parseInt(currentTaskResults.noOfPhoneUnchanged) ;
      if(parseInt(currentTaskResults.noOfPhoneRecords) == 0 ){
        retVal=0;
      }
      return retVal;
    },
    blank() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfPhoneBlank);
      if(parseInt(currentTaskResults.noOfPhoneRecords) == 0 ){
        retVal=0;
      }
      return retVal;
    },    
    improved() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfPhoneValidChanged);
      if(parseInt(currentTaskResults.noOfPhoneRecords) == 0 ){
        retVal=0;
      }
      return retVal;
    },
    valid() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfPhoneValidChanged);
      if(parseInt(currentTaskResults.noOfPhoneRecords) == 0 ){
        retVal=0;
      }
      return retVal;
    },
    missing() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfPhoneBlank);
      if(parseInt(currentTaskResults.noOfPhoneRecords) == 0 ){
        retVal=0;
      }
      return retVal;
    },
    validPercentage() {
      var retVal = '';
      var validated = JSON.parse(this.currentTask.results);
      validated = parseInt(validated.noOfPhoneValidChanged);

      var totalRecords = JSON.parse(this.currentTask.results);
      totalRecords = parseInt(totalRecords.noOfPhoneRecords);   // -  totalRecords.noOfPhoneBlank
      retVal = (validated * 100 / totalRecords).toFixed(1);
      // return ((validated / 100)).toFixed(0)
      if(parseInt(validated.noOfPhoneRecords) == 0 ){
        retVal=0;
      }
      return retVal;
    },
  }
};
</script>

<style>
.phoneNumbers-Container {
  min-height: 400px;
}

.phoneNumbers-Title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.phoneNumbers-Count {
  font-family: 'Poppins';
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.phoneNumbers-Summary {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3D415B;
  opacity: 0.8;
}

.phoneNumbers-Row {
  width: 100%
}

.phoneNumbers-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.phoneNumbers-chartHolder {
  width: 35%;
  margin-left: 30%;
}

.phoneNumbers-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.percentage {
  font-family: 'Poppins';
  font-size: 70px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
  margin-left: 11px;
}
</style>