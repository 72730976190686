<template>
  <div class="border-0">
    <div class="mt-7 pb-0">
      <div class="">
        <div class="d-flex flex-column text-center totalRecords-row">

          <div class="totalRecords-titleHolder">
            <span class="totalRecords-Title">Total Records</span>
            <span> <v-icon id="totalRecords-help-icon" small color="#2C9A65" style="  left: 6px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="totalRecords-help-icon" placement="bottom">
                The total number of individual records supplied
              </b-tooltip></span>
          </div>

          <animate-number style="font-size: 24px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  color: #2C9A65;" from="0" duration="1000" :to=totalRecords()>
          </animate-number>
          <!-- <span class="totalRecords-Count">{{totalRecords()}}</span> -->

          <!-- <span class="totalRecords-Summary">Data uploaded</span>
          <span class="totalRecords-Summary-data">{{formatDate(currentTask.created_at)}}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "totalRecords",
  props: {
    currentTask: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(date);
    },
    totalRecords() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfRecords;
      return retVal;
    }
  }
};
</script>

<style>
.totalRecords-Title {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
  text-align: center;
}

.totalRecords-Count {
  font-family: 'Poppins';
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #2C9A65;
}

.totalRecords-Summary {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.8;
}

.totalRecords-Summary-data {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.5;
}

.totalRecords-row {
  width: 100%;
}

.totalRecords-titleHolder {

  padding-bottom: 10px;
}
</style>