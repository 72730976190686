<template>
  <div class="card  smallbox-Container border-0">
    <div class="card-body">
      <div class="">
        <div class="d-flex flex-column text-left contactNames-Row">
          <div class="contactNames-titleHolder border-0">
            <span class="contactNames-Title">Contact Names</span>
            <span> <v-icon small id="contactNames-help-icon" color="#2C9A65" style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="contactNames-help-icon" placement="bottom">
                The number of individual records which have contact name improvements
              </b-tooltip></span>
          </div>

          <br>

          <div class="d-flex">
            <div class="col-xl-6">

              <div>
                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Imported: </span>
                      <span class="summarycount">{{totalRecords()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Improved: </span>
                      <span class="summarycount" style="color:#33b985;">{{improved()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Unchanged: </span>
                      <span class="summarycount">{{unchanged()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Blank: </span>
                      <span class="summarycount">{{missing()}}</span>
                    </b-col>
                  </b-row>
                </div>

              </div>
            </div>
            <div class="col-xl-6">
              <vue-ellipse-progress :progress=validPercentage() fontSize="25px" fontweight="500" :size="150"
                color="#2C9A65" :thickness="15" style="margin-top: -39px;">
                <span slot="legend-value">%</span>
                <p slot="legend-caption">Improved</p>
              </vue-ellipse-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
// import DonutChart from './DonutChart.js'

export default {
  name: "contactNames",
  props: {
    currentTask: Object,
  },
  components: {
    // DonutChart
  },
  data() {
    return {
      // chartdata: {
      //     labels: ["Improved", "Valid", "Missing"],
      //     datasets: [
      //         {
      //         label: "Data One",
      //         backgroundColor: [ "#33b985", "#3f6f86", "#ec6666"],
      //         data: [ this.improved(), this.valid(), this.missing()]
      //         }
      //     ],
      // },
      options: {
        legend: {
          display: false
        },

      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.fillData()
  },
  methods: {
    totalRecords() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfContactRecords;
      return retVal;
    },
    contactNames() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfContactRecords;
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    unchanged() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var retVal = parseInt(currentTaskResults.noOfContactsUnchanged);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    improved() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfContactsImproved);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    valid() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfContactsValid);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    missing() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var retVal = parseInt(currentTaskResults.noOfContactsBlank);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    validPercentage() {
      var retVal = '';
      var validated = JSON.parse(this.currentTask.results);
      validated = parseInt(validated.noOfContactsImproved);
      var totalRecords = JSON.parse(this.currentTask.results);
      totalRecords = totalRecords.noOfContactRecords;
      retVal = (validated * 100 / totalRecords).toFixed(1);
      // return ((validated / 100)).toFixed(0)
      if (parseInt(validated.noOfContactRecords) == 0) {
        retVal = 0;
      }

      return retVal;
    },
  }
};
</script>

<style>
.contactNames-Container {
  min-height: 400px;
}

.contactNames-Title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.contactNames-Count {
  font-family: 'Poppins';
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.contactNames-Summary {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3D415B;

}

.contactNames-Row {
  width: 100%
}

.contactNames-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.contactNames-chartHolder {
  width: 35%;
  margin-left: 30%;
}

.contactNames-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.percentage {
  font-family: 'Poppins';
  font-size: 70px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
  margin-left: 11px;
}
</style>