<template>
  <div class="card smallbox-Container border-0">
    <div class="card-body">
      <div class="">
        <div class="d-flex flex-column text-left">
          <div class="emailAddressesBase-titleHolder border-0">
            <span class="emailAddressesBase-Title">Email Addresses</span>
            <span> <v-icon small id="emailAddressesBase-help-icon" color="#2C9A65" style="  left: 8px;"
                class="review-Helpbutton-Container">mdi-information-outline</v-icon>
              <b-tooltip target="emailAddressesBase-help-icon" placement="bottom">
                A breakdown of the email addresses we were able to validate for each record
              </b-tooltip></span>
          </div>

          <!-- <div><br/>
            <v-icon
              id="emailAddressesBase-help-icon"
              small
            class="review-Helpbutton-Container">mdi-help-circle-outline</v-icon>
            <b-tooltip target="emailAddressesBase-help-icon" placement="bottom">
              Broken down to show Valid, Invalid and Blank Email Addresses
            </b-tooltip>
          </div> -->
          <!-- <div class="row">
            <div class="col-sm-4 emailAddressesBase-chartHolder">
            <donut-chart :chart-data="chartdata" :options="options"></donut-chart>
          </div>
         
           </div> -->

          <!-- <div class="progress blue">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">{{validPercentage()}}%</div>
            </div> -->

          <br>
          <div class="d-flex">
            <div class="col-xl-6">

              <div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Imported: </span>
                      <span class="summarycount">{{totalRecords()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Domain Validated: </span>
                      <span class="summarycount" style="color:#33b985;">{{valid()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Invalid: </span>
                      <span class="summarycount">{{invalid()}}</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span>&nbsp;</span>
                    </b-col>
                  </b-row>
                </div>

                <div>
                  <b-row>
                    <b-col>
                      <span class="summarydata">Blank: </span>
                      <span class="summarycount">{{blank()}}</span>
                    </b-col>
                  </b-row>
                </div>


              </div>
            </div>
            <div class="col-xl-6">
              <vue-ellipse-progress :progress=validPercentage() fontSize="30px" fontweight="500" :size="150"
                :thickness="15" color="#2C9A65" style="margin-top: -39px;">
                <span slot="legend-value">%</span>
                <p slot="legend-caption" style="width: 65px;">Domain Validated</p>
              </vue-ellipse-progress>
            </div>
          </div>


          <!-- <div style="margin-left: 26px; margin-top: -3px;">			
              <vue-ellipse-progress 
                  :progress=validPercentage()
                  fontSize="30px"
                  fontweight="500" 
                  :size="150"
              >
              <span slot="legend-value">%</span>
              <p slot="legend-caption">Domain Validated</p>    
              </vue-ellipse-progress>
          </div> -->

          <!-- <div style=" margin-top: -10%;">
              <span class="percentage">{{validPercentage()}}% </span>
              <span style=" color: #3f6f86; margin-left: -12px; font-size: 14px; font-weight: 300;">Validated</span>
            </div> -->

          <!-- <br>
          <b-row class="emailAddressesBase-Row">
            <b-col cols="4" style="margin-bottom: 10px;text-align: right;">
              <span class="emailAddressesBase-Summary" >{{totalRecords()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                <span class="emailAddressesBase-Summary" style="color:#3f6f86;">Imported</span>
                
              </b-col>
              <b-col cols="4" style="margin-bottom: 10px; text-align: right;">
                <span class="emailAddressesBase-Summary" >{{valid()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
               
                <span class="emailAddressesBase-Summary" style="color:#33b985;">Domain Validated</span>
              </b-col>
              <b-col cols="4" style="margin-bottom: 10px;text-align: right;">
                <span class="emailAddressesBase-Summary" style="text-align: right;">{{invalid()}}</span>
                
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                <span class="emailAddressesBase-Summary" style="color:#black;">Invalid/Blank</span>

              </b-col>
          </b-row> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import DonutChart from './DonutChart.js'

export default {
  name: "emailAddresses",
  props: {
    currentTask: Object,
  },
  components: {
    // DonutChart
  },
  data() {
    return {
      // chartdata: {
      //     labels: ["Imported", "Valid", "Invalid"],
      //     datasets: [
      //         {
      //         label: "Data One",
      //         backgroundColor: ["#3f6f86",  "#33b985" ,"#ec6666"],
      //         data: [this.totalRecords(), this.valid(), this.invalid()]
      //         }
      //     ],
      // },
      options: {
        legend: {
          display: false
        },

      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.fillData()
  },
  methods: {
    emailAddresses() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfEmailRecords;
      return retVal;
    },
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    invalid() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      // retVal = parseInt(currentTaskResults.noInvalidEmails) + parseInt(currentTaskResults.noInvalidEmails);
      retVal = parseInt(currentTaskResults.noInvalidEmails);
      if(parseInt(currentTaskResults.noOfEmailRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    blank() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfEmailRecords) - (parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails));
      if(parseInt(currentTaskResults.noOfEmailRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    valid() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfEmailValid);
      if(parseInt(currentTaskResults.noOfEmailRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    totalRecords() {
      var retVal = '';
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfEmailRecords;
      if(parseInt(currentTaskResults.noOfEmailRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    validPercentage() {
      var retVal = '';
      var validated = JSON.parse(this.currentTask.results);
      validated = parseInt(validated.noOfEmailValid);
      var totalRecords = JSON.parse(this.currentTask.results);
      totalRecords = parseInt(totalRecords.noOfEmailRecords) - (parseInt(totalRecords.noInvalidEmails));
      retVal = (validated * 100 / totalRecords).toFixed(1);
      if(parseInt(validated.noOfEmailRecords)==0){
        retVal=0;
      }
      return retVal;
    },
    // blank() {
    //     var retVal = '';
    //     var currentTaskResults = JSON.parse(this.currentTask.results);
    //     retVal = parseInt(currentTaskResults.noOfEmailRecords) - (parseInt(currentTaskResults.noOfEmailValid) + parseInt(currentTaskResults.noInvalidEmails) );
    //     return retVal;
    // }          

  }
};
</script>

<style>
.emailAddressesBase-Container {
  min-height: 400px;
}

.emailAddressesBase-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657D;
}

.emailAddressesBase-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.emailAddressesBase-Summary {
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3D415B;

}

.emailAddressesBase-Row {
  width: 100%;

}

.emailAddressesBase-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.emailAddressesBase-chartHolder {
  width: 35%;
  margin-left: 30%;
}

.emailAddressesBase-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.percentage {
  font-size: 120px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
  margin-left: 3px;
}
</style>