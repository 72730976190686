<template>
    <div class="modalbodypay">
        <div class="modalfooter" style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -26px;
      ">
            <p style="text-align: left; width: 738px; font-size: 16px">
                Please select the cleansed fields you wish to export below. Original
                supplied data will remain at the beginning of the spreadsheet. If you
                wish to remove this, please tick the option below.
            </p>
            <div style="display: flex; align-items: center; justify-content: end">
                <button type="button" class="btn btn-secondary" @click="toggleTagSelection"
                    style="font-size: 17px; background: grey; color: white">
                    Select All
                </button>
            </div>
        </div>

        <div>
            <div class="d-flex">
                <div class="col-xl-4" style="
            width: 1250px;
            height: 480px;
            border: 2px solid #a99c9c;
            overflow-x: auto;
          ">
                    <div id="wrapper" >
                        <div  class="control mt-5">
                            <div v-on:click="contactsub = !contactsub" class="d-flex cursor-pointer" style="align-items: center; font-size: 17px">
                                <div class="mr-3">
                                    <i v-if="!contactsub" class="fas fa-chevron-right"></i>
                                    <i v-if="contactsub" class="fas fa-chevron-down"></i>
                                </div>
                                <div  style="font-size: 17px; font-weight: 600">Contact</div>
                            </div>

                            <div v-if="contactsub" id="hide" class="ml-5">
                                <div class="list-group">
                                    <div @click="clickTags(allData)" v-for="allData in allData.slice(0, 9)" :key="allData"
                                        class="list-group-item list-group-item-action cursor-pointer"
                                        :class="[{ 'selected-item': isSelected(allData) }, { 'unselected-item': !isSelected(allData) }]">
                                        <i class="fa fa-plus"></i> &nbsp; &nbsp; {{ allData }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  class="control mt-5">
                            <div v-on:click="addresssub = !addresssub" class="d-flex cursor-pointer" style="align-items: center; font-size: 17px">
                                <div class="mr-3">
                                    <i v-if="!addresssub" class="fas fa-chevron-right"></i>
                                    <i v-if="addresssub" class="fas fa-chevron-down"></i>
                                </div>
                                <div  style="font-size: 17px; font-weight: 600">Address</div>
                            </div>

                            <div v-if="addresssub" id="hide" class="ml-5">
                                <div class="list-group">
                                    <div @click="clickTags(allData)" v-for="allData in allData.slice(10, 21)" :key="allData"
                                        class="list-group-item list-group-item-action cursor-pointer"
                                        :class="[{ 'selected-item': isSelected(allData) }, { 'unselected-item': !isSelected(allData) }]">
                                        <i class="fa fa-plus"></i> &nbsp; &nbsp; {{ allData }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div  class="control mt-5">
                            <div v-on:click="emailsub = !emailsub" class="d-flex cursor-pointer" style="align-items: center; font-size: 17px">
                                <div class="mr-3">
                                    <i v-if="!emailsub" class="fas fa-chevron-right"></i>
                                    <i v-if="emailsub" class="fas fa-chevron-down"></i>
                                </div>
                                <div  style="font-size: 17px; font-weight: 600">Email</div>
                            </div>

                            <div v-if="emailsub" id="hide" class="ml-5">
                                <div class="list-group">
                                    <div @click="clickTags(allData)" v-for="allData in allData.slice(21, 30)" :key="allData"
                                        class="list-group-item list-group-item-action cursor-pointer"
                                        :class="[{ 'selected-item': isSelected(allData) }, { 'unselected-item': !isSelected(allData) }]">
                                        <i class="fa fa-plus"></i> &nbsp; &nbsp; {{ allData }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div  class="control mt-5">
                            <div @click="telephonesub = !telephonesub" class="d-flex cursor-pointer" style="align-items: center; font-size: 17px">
                                <div class="mr-3">
                                    <i v-if="!telephonesub" class="fas fa-chevron-right"></i>
                                    <i v-if="telephonesub" class="fas fa-chevron-down"></i>
                                </div>
                                <div  style="font-size: 17px; font-weight: 600">Telephone</div>
                            </div>

                            <div v-show="telephonesub" class="ml-5">
                                <div class="list-group">
                                    <div @click="clickTags(allData)" v-for="allData in allData.slice(31, 42)" :key="allData"
                                        class="list-group-item list-group-item-action cursor-pointer"
                                        :class="[{ 'selected-item': isSelected(allData) }, { 'unselected-item': !isSelected(allData) }]">
                                        <i class="fa fa-plus"></i> &nbsp; &nbsp; {{ allData }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8" style="
            width: 1250px;
            height: 480px;
            margin-left: 19px;
            border: 2px solid #a99c9c;
            overflow-x: auto;
          ">
                    <!-- <b-form-tags v-model="formValue" multiple no-outer-focus class="mb-2" tag-variant="secondary">
              <template v-slot="{ tags, tagVariant, removeTag }">
                <div class="d-inline-block" style="font-size: 17px;">
                  <b-form-tag v-for="tag in tags" @remove="removeTag(tag)" :key="tag" :title="tag" :variant="tagVariant"
                    class="mr-1">{{ tag }}</b-form-tag>
                </div>
                <input v-model="tagInput" @keydown.enter.prevent="addTag(tagInput)" type="text" class="form-control"
                  placeholder="Add a tag">
              </template>
            </b-form-tags> -->
                    <template>
                        <div class="tag-container mt-5">
                            <TagItem v-for="tag in selectedTags" :key="tag" :tag="tag" @remove="toggleDelete">
                            </TagItem>
                        </div>
                    </template>
                </div>
                <br />
            </div>

            <div class="modalfooter mt-5" style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
                <!-- <button type="button" class="btn  btn-danger" @click="getvalue(true)" style="font-size: 17px;">Refresh</button> -->

                <button type="button" class="btn btn-danger" @click="selectedTags = []" style="font-size: 17px">
                    Clear
                </button>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="checkboxValue"
                        value="option1" style="font-size: 17px" />
                    <label class="form-check-label" for="inlineCheckbox1" style="font-size: 17px">Remove Supplied
                        data</label>
                </div>
                <button type="button" class="btn btn-primary" @click="downloadcheck()" style="font-size: 17px">
                    Download Selected
                </button>
                <!-- <button type="button" class="btn  btn-primary" @click="downloadfile(true)" style="font-size: 17px;">
            Download All</button> -->
            </div>
        </div>
    </div>
</template>
  
  
<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import TagItem from "./tagItemview.vue";

export default {
    name: "downloadmodal",
    components: {
        TagItem,
    },
    props: ["currentTask_id", "currentTask_title"],
    data() {
        return {
            outputcolumnalter: false,
            selectedTags: [],
            formValue: [],
            tagOptions: [],
            allData: [],
            customview: false,
            apiLink: "",
            checkboxValue: false,
            contactsub: false,
            addresssub: false,
            emailsub: false,
            telephonesub: false,
            contactsubtitle: [],
            showPlaces: false,
        };
    },

    watch: {
        // formValue(newVal, oldVal) {
        //     const removed = oldVal.filter((v) => !newVal.includes(v));
        //     console.log(removed);
        // },
    },

    mounted() {
        this.getvalue();
    },

    methods: {
        isSelected(item) {
            return this.selectedTags.includes(item);
        },
        toggleTagSelection() {
            this.selectedTags=[];
            this.allData.forEach((value, index) => {
                this.selectedTags.push(value);
                console.log(value);
                console.log(index);
            });
            // this.tagOptions.push(this.allData);
            //   }
            //   console.warn(this.selectedTags);
        },
        toggleDelete(tag) {
            console.warn("delete called", tag);
            this.selectedTags = this.selectedTags.filter((t) => t !== tag);
        },

        getvalue($flag = false) {
            ApiService.get("/apps/todo/task/getOutputcolumns/" + this.currentTask_id)
                .then((response) => {
                    if ($flag == true) {
                        console.warn(response.data.outputcolumn);
                        this.allData = response.data.outputcolumn;
                        this.formValue = response.data.outputcolumn;
                    } else {
                        console.warn('response ',response.data.outputcolumnnew);
                        this.allData = response.data.outputcolumn;
                        this.formValue = response.data.outputcolumnnew;
                        if (this.formValue === null || this.formValue === undefined) {
                            this.formValue = this.allData;
                        }
                        this.selectedTags = this.formValue;
                        console.warn('selected tag',this.selectedTags.length);
                        // if (typeof this.allData == undefined) {
                        //   this.allData = response.data.outputcolumn;
                        //   this.formValue = response.data.outputcolumn;
                        // } else if (this.allData.length == 0) {
                        //   this.allData = response.data.outputcolumn;
                        //   this.formValue = response.data.outputcolumnnew;
                        // }
                    }
                    //   if (this.allData.length != this.formValue.length) {
                    //     // this.tagOptions = this.allData;
                    //     this.selectedTags = this.formValue;
                    //   } else {
                    //     this.tagOptions = this.allData;
                    //     this.selectedTags = [];
                    //   }
                    //   if(response.data.outputremovesuppdata == true){
                    //       this.checkboxValue = true;
                    //   }
                })
                .catch(() => {
                    console.warn('api error - catch event');
                    this.allData = [];
                    this.formValue = [];
                });

                // console.warn('get value function');
                // console.warn(this.allData);
                // console.warn(this.formValue);
                // console.warn(this.selectedTags);
                // this.selectedTags = this.formValue;

            // var outputcolumns_altered = false;
            // var formArray = this.allData;
            // ApiService.post("/apps/todo/task/OutputcolumnsAltered/" + this.currentTask_id, { outputcolumnalter: outputcolumns_altered, outputcolumnnew: formArray })
            //   .then(response => {
            //     console.log('checking ', response);
            //   })

            // if (this.allData.length == 0) {
            //   this.downloadfile(true);
            // }
        },

        downloadcheck($flag = false) {
            Swal.fire({
                title: "Please Wait",
                allowOutsideClick: false,
                onOpen: () => {
                    Swal.showLoading();
                },
            });

            //check output column length
            if ($flag == false) {
                console.warn("allData ", this.allData.length);
                console.warn("form value ", this.formValue.length);
                console.warn("form value ", this.formValue);
                console.warn("selected tag", this.selectedTags);

                if (this.selectedTags.length != 0) {
                    this.formValue = this.selectedTags;
                    console.warn("selected value ", this.selectedTags);
                }

                var outputcolumns_altered = true;
                var formArray = [];

                if (
                    (this.allData.length != this.formValue.length &&
                        this.allData.length > 0) ||
                    this.checkboxValue == true
                ) {
                    outputcolumns_altered = true;
                    formArray = this.formValue;
                    ApiService.post(
                        "/apps/todo/task/OutputcolumnsAltered/" + this.currentTask_id,
                        {
                            outputcolumnalter: outputcolumns_altered,
                            outputcolumnnew: formArray,
                            outputremovesuppdata: this.checkboxValue,
                        }
                    )
                        .then((response) => {
                            console.log("  ", response);
                            this.downloadfile();
                        })
                        .catch((e) => {
                            console.warn("error ", e);
                        });
                } else {
                    console.warn("false conditon");

                    outputcolumns_altered = false;
                    formArray = this.allData;
                    ApiService.post(
                        "/apps/todo/task/OutputcolumnsAltered/" + this.currentTask_id,
                        {
                            outputcolumnalter: outputcolumns_altered,
                            outputcolumnnew: formArray,
                            outputremovesuppdata: this.checkboxValue,
                        }
                    )
                        .then((response) => {
                            console.log("checking ", response);
                        })
                        .catch((e) => {
                            console.warn("error ", e);
                        });

                    this.downloadfile(true);
                }
            }

            if ($flag == true) {
                this.downloadfile();
            }
        },
        clickTags(allData) {
            if (!this.selectedTags.includes(allData)) {
                this.selectedTags.push(allData);
            }else if (this.selectedTags.includes(allData)) {
                this.selectedTags = this.selectedTags.filter(item => item !== allData);
            }
            // this.tagOptions.push(allData);
        },
        downloadfile(dflag = false) {
            Swal.close();
            Swal.fire({
                title: "Please Wait",
                allowOutsideClick: false,
                onOpen: () => {
                    Swal.showLoading();
                },
            });

            if (dflag == true) {
                this.apiLink = "apps/todo/task/downloadall/" + this.currentTask_id;
            } else {
                this.apiLink = "apps/todo/task/downloadselected/" + this.currentTask_id;
            }
            ApiService.setHeader();
            // ApiService.getblob
            ApiService.getblob(this.apiLink)
                .then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");

                    var date1 = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
                    var outputFile = this.currentTask_title;
                    outputFile = outputFile + "_RMDCLEANSE_" + date1;
                    outputFile = outputFile.replace(".", "_").replace("/", "_");
                    outputFile = outputFile + ".xlsx";

                    fileLink.href = fileURL;
                    // fileLink.setAttribute('download', this.currentTask_id + '.xlsx');
                    fileLink.setAttribute("download", outputFile);
                    document.body.appendChild(fileLink);

                    Swal.close();

                    fileLink.click();
                })
                .catch(() => {
                    Swal.close();
                    Swal.fire({
                        title: "Sorry Download Error, Try Later",
                        confirmButtonClass: "btn btn-secondary",
                    });
                });
        },
    },
};
</script>
<style scoped>
.modal_left_text {
    font-size: 17px;
    font-weight: 500;
}

.modal_right_text {
    font-size: 17px;
    font-weight: 500;
}

.modalfooter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalRow {
    align-items: center;
    margin-top: 15px;
}

/* modal */
#modal1 .modal-header {
    border-bottom: 0 none;
}

#modal1 .modal-header .close {
    float: right;
    font-size: 31px;
    font-weight: 700;
    color: #000;
}

#modal1 .modal-dialog {
    position: relative;
    background-color: #e9eef3;
    position: absolute;
    top: 80px;
    right: 29px;
    width: 500px;
}

#inputBox {
    border: 1px solid #ccc;
    font-size: 14px;
    padding-right: 20px;
}

#inputBox.grayedOut {
    background-color: #eee;
}

.closeIcon {
    position: absolute;
    color: #6f7392;
    width: 24px;
    height: 24px;
    margin-left: 97px;
    margin-top: -32px;
    cursor: pointer;
}

.form-check {
    margin-right: 720px;
}

.selected-item {
  border: 2px solid #33b985; /* Change the border color for selected items */
}

.unselected-item {
  border: 2px solid #ffffff; /* Change the border color for unselected items */
}

/* 
.tag-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    white-space: nowrap;
  }  */
</style>